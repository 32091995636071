/* eslint-disable react/button-has-type */
import React, { useId } from 'react';

import Spinner from './Spinner';
import { classNames } from '../util/functions';

function Button({
  type,
  title,
  size,
  icon,
  loading,
  onClick,
  variant,
  disabled,
  iconPosition,
  customTxtStyles,
  customStyleClasses,
}) {
  const id = useId();

  const commonStyles = 'flex justify-center hover:opacity-75 items-center appearance-none focus:outline-none';
  const sizeStyles = `rounded-full px-4 sm:px-10 py-2 h-auto sm:h-10 ${
    size === 'block' && 'w-full'
  }`;
  const variantStyles = `${
    variant === 'primary'
      ? 'bg-primary text-white disabled:opacity-75 rounded-md'
      : variant === 'secondary'
        ? 'rounded-md shadow-sm font-medium text-white bg-secondary focus:outline-none disabled:opacity-50 hover:bg-gray-25'
        : variant === 'white'
          ? 'border shadow-sm font-medium text-white bg-lightWhite focus:outline-none disabled:opacity-50 hover:bg-gray-25'
          : variant === 'outline'
            ? 'bg-transparent border border-primary text-white disabled:opacity-75 rounded-md' : ''
  }  text-xs md:text-sm font-medium`;

  // Important: The <span must be there as chrome won't show a tooltip on a disabled element.

  return (
    <>
      <button
        type={type || 'button'}
        disabled={disabled}
        className={classNames(
          variantStyles,
          sizeStyles,
          commonStyles,
          customStyleClasses,
        )}
        onClick={onClick}
        id={id}
      >
        {!loading ? (
          <>
            {(iconPosition === 'left' && icon) && <span className="mr-2">{icon}</span>}
            <p className={classNames('text-lg', customTxtStyles)}>{title}</p>
            {(iconPosition === 'right' && icon) && <span className="ml-2">{icon}</span>}
          </>
        ) : (
          <div className="px-5 -mt-0.5">
            <Spinner size={15} />
          </div>
        )}
      </button>
    </>
  );
}

Button.defaultProps = {
  variant: 'primary',
  iconPosition: 'left',
  tooltipPosition: 'bottom',
};

export default Button;
