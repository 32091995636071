import React, { useContext, useRef } from 'react';
import { PlusCircle } from 'react-feather';
import { Draggable } from 'react-beautiful-dnd';

import toast from 'react-hot-toast';
import * as Colors from '../../config/colors';
import { dealer, seller } from '../../static/payments-data';
import { UserContext } from '../../Provider/UserProvider';

/* =============================================================================
<ImageMuiltipleUploadAddBtn />
============================================================================= */
const ImageMuiltipleUploadAddBtn = ({
  value,
  numOfImgs,
  onChange,
}) => {
  const fileInput = useRef();
  // GETTING CURRENT USER
  const { user } = useContext(UserContext);

  const userType = user?.type;
  const packageId = user?.postPackage?.packageId;
  const postPackage = userType === 'private seller'
    ? seller?.find(p => p?.priceId === packageId)
    : Object.values(dealer)
      ?.flatMap(d => [...d])
      ?.find(d => d?.priceId === packageId);
  const allowedImages = numOfImgs || postPackage?.images || 5;

  const _handleImageSelect = async (e) => {
    try {
      const files = Object.values(e.target.files).map((file) => URL.createObjectURL(file));
      const numOfValues = value?.length;

      if (numOfValues >= allowedImages) {
        toast.error(numOfImgs ? `Only ${allowedImages} Allowed`
          : 'Please buy a plan to upload more than 5 images');
      } else if (numOfValues) {
        onChange([...value, ...files]);
      } else {
        onChange(files);
      }
    } catch (err) {
      toast.error(err?.message);
    }
  };

  return (
    <li className="ml-auto mr-auto">
      <button
        type="button"
        className="w-52 h-44 space-y-3 rounded-lg mt-2 border-2 border-dashed border-primary flex flex-col items-center justify-center p-2 "
        onClick={() => fileInput.current.click()}
      >
        <PlusCircle color={Colors.primary} size={32} />
        <p className="text-primary text-sm">
          Select 5 images
        </p>
      </button>
      <input
        type="file"
        ref={fileInput}
        id="file-input"
        name="file-input"
        multiple="multiple"
        className="w-0 h-0 opacity-0  overflow-hidden absolute z-0"
        accept="image/png, image/jpeg"
        onChange={_handleImageSelect}
      />
    </li>
  );
};

export default ImageMuiltipleUploadAddBtn;
