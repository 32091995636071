/* eslint-disable prefer-template */
/* eslint-disable quotes */
/* eslint-disable radix */
import * as Yup from 'yup';

export const generateFormDefaultValues = (fields = [], values) => {
  const defaultValues = {
    location: values
      ? values?.location
      : {
        name: '', lat: 0, lng: 0, zipCode: '',
      },
    includePhone: values ? values.includePhone : false,
    photos: values ? values?.photos : [],
  };

  fields?.forEach(field => {
    const { name, type } = field;

    switch (type) {
      case 'select':
      case 'text':
      case 'textArea':
        defaultValues[name] = values ? values[name] : '';
        break;
      case 'number':
        defaultValues[name] = values ? values[name].toString() : '';
        break;
      case 'modal':
        defaultValues[name] = field.isMultiSelect
          ? values
            ? values[name]
            : []
          : values
            ? values[name]
            : '';
        break;
      // Add more cases for other field types as needed
      default:
        break;
    }
  });

  return defaultValues;
};

export const generateYupSchema = fields => {
  const schema = {
    location: Yup.object({
      zipCode: Yup.string(),
      name: Yup.string(),
      lat: Yup.number(),
      lng: Yup.number(),
    }).test({
      test: d => d?.zipCode,
      message: 'Please add location',
    }),
    photos: Yup.array().of(Yup.string())
      .test({
        message: 'Select atlest 1 image',
        test: images => Object.keys(images).length >= 1,
      })
      .required('Select atlest 1 image'),
  };

  fields.forEach(field => {
    const { name, type } = field;

    switch (type) {
      case 'number':
        if (name === 'year' || name === 'price') {
          schema[name] = Yup.number()
            .typeError('Must be a valid number')
            .required('This field is required');
        } else {
          schema[name] = Yup.string().typeError('Must be a valid number');
        }
        break;
      case 'text':
      case 'textArea':
        if (name === 'model') {
          schema[name] = Yup.string().trim().required('This field is required');
        } else {
          schema[name] = Yup.string().trim();
        }
        break;
      case 'select':
        schema[name] = Yup.string().required('Atlest select one option');
        break;
      case 'modal':
        if (name === 'make') {
          schema[name] = field.isMultiSelect
            ? Yup.array()
              .of(Yup.string().required('Text Required'))
              .test({
                message: 'At least select one feature',
                test: arr => arr.length !== 0,
              })
              .required('This field is required')
            : Yup.string().required('This field is required');
        } else {
          schema[name] = field.isMultiSelect
            ? Yup.array().of(Yup.string())
            : Yup.string();
        }
        break;
      default:
        break;
    }
  });

  return Yup.object().shape(schema);
};
export const generateGuestId = () => {
  const id = JSON.parse(localStorage.getItem('@guestId'));
  if (id) {
    return id;
  }
  const generateId = `GuestId${Math.random().toString(14).slice(2)}`;
  localStorage.setItem('@guestId', JSON.stringify(generateId));
  return generateId;
};
export const getBlobFroUri = async uri => {
  const blob = await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function onLoad() {
      resolve(xhr.response);
    };
    xhr.onerror = function onError(e) {
      reject(new TypeError('Network request failed'));
    };
    xhr.responseType = 'blob';
    xhr.open('GET', uri, true);
    xhr.send(null);
  });

  return blob;
};

export const getEditFormDefaultValues = post => {
  const fields = { ...post };

  for (const key in fields) {
    if (typeof fields[key] === 'number') {
      fields[key] = fields[key].toString();
    } else {
      fields[key] = fields[key];
    }
  }

  delete fields.author;
  delete fields.createdAt;
  delete fields.photos;
  delete fields.includePhone;
  delete fields.updatedAt;

  return fields;
};

export const filterCars = (carData = [], filters) => {
  if (filters === undefined) {
    return carData; // Return the entire carData array if filters is undefined
  }

  // Check each filter condition
  return carData.filter(car => (
    (filters.bodyStyle.length === 0
        || filters.bodyStyle.includes(car.bodyStyle))
      && (filters.condition === '' || filters.condition === car.condition)
      && (filters.fuel.length === 0 || filters.fuel.includes(car.fuel))
      && (filters.location === '' || filters.location === car.location)
      && (filters.makes.length === 0 || filters.makes.includes(car.make))
      && (filters.model === ''
        || filters.model?.toLowerCase() === car.model?.toLowerCase())
      && (filters.priceFrom === ''
        || car.price >= parseFloat(filters.priceFrom))
      && (filters.priceTo === '' || car.price <= parseFloat(filters.priceTo))
      && (filters.sellerType === '' || filters.sellerType === car.sellerType)
      && (filters.title.length === 0 || filters.title.includes(car.title))
      && (filters.transmission.length === 0
        || filters.transmission.includes(car.transmission))
      && (filters.yearFrom === '' || car.year >= parseInt(filters.yearFrom))
      && (filters.yearTo === '' || car.year <= parseInt(filters.yearTo))
      && (filters.zipCode === '' || filters.zipCode === car.location.zipCode)
  ));
};

export const getDistance = (lat1, lon1) => new Promise((resolve, reject) => {
  const location = window.navigator && window.navigator.geolocation;

  if (location) {
    location.getCurrentPosition((position) => {
      const lat2 = position.coords.latitude;
      const lon2 = position.coords.longitude;

      const R = 6371; // Radius of the earth in km
      const dLat = deg2rad(lat2 - lat1); // deg2rad below
      const dLon = deg2rad(lon2 - lon1);
      const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
              + Math.cos(deg2rad(lat1))
                * Math.cos(deg2rad(lat2))
                * Math.sin(dLon / 2)
                * Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c; // Distance in km

      resolve(Math.round(d));
    }, (error) => {
      reject(error.message);
    });
  }
});

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export function getInitials(name) {
  if (typeof name !== 'string' || !name.trim()) {
    return '';
  }

  const words = name.split(' ');
  let initials = '';

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < words.length; i++) {
    const word = words[i];

    if (!word) {
      // eslint-disable-next-line no-continue
      continue;
    }

    initials += word[0].toUpperCase();

    if (initials.length >= 2) {
      break;
    }
  }

  return initials;
}

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
