import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  Button,
  FormDropDown, Input, LocationButton, Modal,
} from '../../common';
import { emptyFilterForm, filterFormFields, searchFilterFormFields } from '../../static/filter';

/* =============================================================================
<HomeFilterModal />
============================================================================= */
const HomeFilterModal = ({
  visible,
  filters,
  onClose,
  category,
  onFilterChange,
}) => {
  const fields = category === 'search' ? searchFilterFormFields : filterFormFields[category];

  const {
    reset,
    control,
    register,
    handleSubmit,
  } = useForm({
    defaultValues: filters || emptyFilterForm,
  });

  const onSubmit = values => {
    onFilterChange(values);
    onClose();
  };

  return (
    <Modal visible={visible} heading="Filters" onClose={onClose} childrenStyles="pt-0" transitionChildStyles="xsm:p-0 xsm:h-full" customStyles="xsm:relative xsm:h-full w-full xsm:rounded-none">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 overflow-y-scroll filter-scroll px-2 pb-5" style={{ height: '73vh' }}>
        <div className="flex justify-end ">
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <LocationButton showDistance value={value} onChange={e => console.log('onChange', e)} />
            )}
            name="location"
          />
        </div>
        {fields?.map(field => {
          if (field.type === 'text') {
            return (
              <Input label={field.label} formProps={register(field.name)} />
            );
          }
          if (field.type === 'number' && field.name === 'year') {
            return (
              <div className="flex items-center space-x-4">
                <Input label="Year (from)" formProps={register('yearFrom')} />
                <Input label="Year (to)" formProps={register('yearTo')} />
              </div>
            );
          }

          if (field.type === 'number' && field.name === 'price') {
            return (
              <div className="flex items-center space-x-4">
                <Input label="Price (from)" formProps={register('priceFrom')} />
                <Input label="Price (to)" formProps={register('priceTo')} />
              </div>
            );
          }

          if (field.type === 'modal') {
            return (
              <FormDropDown
                key={field.name}
                data={field.data}
                label={field.label}
                control={control}
                name={field.name}
                isMultiSelect={field.isMultiSelect}
                error={[field.name]?.message}
              />
            );
          }
          return null;
        })}

      </form>
      <div className="sticky xsm:absolute bottom-0 xsm:px-5 left-0 right-0 bg-black flex items-center space-x-5 pt-5 py-2">
        <Button
          size="block"
          variant="outline"
          title="Reset"
          onClick={() => reset(emptyFilterForm)}
        />
        <Button
          size="block"
          title="APPLY"
          type="submit"
        />
      </div>
    </Modal>
  );
};

/* Export
============================================================================= */
export default HomeFilterModal;
