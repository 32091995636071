import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import '../config/carouselStyles.css';
import FsLightbox from 'fslightbox-react';
import { classNames } from '../util/functions';
import useWindowDimensions from '../hooks/useWindowDimensions';

/* =============================================================================
<PhotosCarousel />
============================================================================= */
const PhotosCarousel = ({
  photos, children, customStyles, imgStyles,
}) => {
  const { width } = useWindowDimensions();
  const [selectedImg, setSelectedImg] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <div className={classNames('h-auto max-h-1/2 md:w-2/3 relative', customStyles)}>
      <div className="bg-black bg-opacity-75  absolute top-3 left-2 z-10 rounded-lg py-1 px-4">
        <p className="text-white text-sm">{`${currentIndex + 1} of ${photos?.length}`}</p>
      </div>
      <Carousel
        swipeable
        dynamicHeight
        showArrows={false}
        className="relative"
        showThumbs={width > 480}
        renderThumbs={() => photos.map((item) => <img src={item} className="w-auto h-10 border-none" alt={`thumnail ${item}`} />)}
        onChange={(index) => setCurrentIndex(index)}
      >
        {photos.map((item, index) => (
          <button
            type="button"
            key={`slide${index + 1}`}
            onClick={() => setSelectedImg(!selectedImg)}
            className="w-full overflow-hidden"
            style={{ height: width > 480 && 500 }}
          >
            <img src={item} className={classNames('w-full sm-1:h-full object-cover xsm:h-[245px]  rounded-2xl xsm:rounded-none', imgStyles)} alt={item} />

          </button>
        ))}
      </Carousel>
      {!!children && children}
      <FsLightbox
        toggler={selectedImg}
        type="image"
        sources={photos}
      />
    </div>
  );
};

PhotosCarousel.defaultProps = {
  photos: [],
};

const propsAreEqual = (prev, next) => prev?.photos?.length === next?.photos?.length;

export default React.memo(PhotosCarousel, propsAreEqual);
