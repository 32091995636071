/* eslint-disable react-hooks/rules-of-hooks */
import { useInfiniteQuery } from 'react-query';
import request from '../../util/request';
import { auth } from '../../config/firebase';
import { filterCars } from '../../util/functions';

/**
 * useGetSearchedPosts
 */

export const useGetSearchedPosts = (query, filters, guestId) => useInfiniteQuery(
  ['searchPosts', query],
  async ({ pageParam = 0 }) => {
    const userId = auth?.currentUser?.uid;
    const url = userId
      ? `/search?userId=${userId}&query=${query}&page=${pageParam}`
      : `/search?userId=${guestId}&query=${query}&page=${pageParam}`;

    const payload = await request({
      url,
      method: 'GET',
    });
    return payload.data;
  },
  {
    select: payload => {
      const flattenData = payload?.pages
        ? payload?.pages
          ?.flatMap(page => [...page])
          .sort((a, b) => Number(b?.isFeatured) - Number(a?.isFeatured))
        : [];

      const filteredData = filterCars(flattenData, filters);

      return filteredData;
    },
    enabled: false,
    getNextPageParam: ({ pagination }) => (
      pagination?.has_more_pages ? pagination.page + 1 : undefined),
  },
);
