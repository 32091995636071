/* eslint-disable consistent-return */
import React, { useContext } from 'react';
import { Timestamp, collection, doc } from 'firebase/firestore';
import { useHistory } from 'react-router-dom';
import {
  useFirestoreCollectionMutation,
  useFirestoreDocumentData,
  useFirestoreDocumentMutation,
} from '@react-query-firebase/firestore';

import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from '../../common';
import {
  auth,
  conversationCollectionRef,
  firestore,
} from '../../config/firebase';
import { client } from '../../config/react-query';
import { UserContext } from '../../Provider/UserProvider';

/* =============================================================================
<PostActions />
============================================================================= */
const PostActions = ({ author, postId }) => {
  const navigation = useHistory();
  const { userId, user } = useContext(UserContext);
  const currentuserName = auth?.currentUser?.displayName;
  const authroId = author?.id || '';

  // CREATING CONVERSATION UNIQUE IDENTIFIER ID
  const conversationMembers = [authroId, userId];
  conversationMembers.sort();
  const conversationId = conversationMembers.join('_');

  // GETTING CONVERSATION OF CURRENT USER AND AUTHOR IF ANY
  const conversationRef = doc(conversationCollectionRef, conversationId);
  const { data: conversation } = useFirestoreDocumentData(
    ['conversation', conversationId],
    conversationRef,
  );

  // CREATE CONVERSATION CALL
  const {
    isLoading,
    mutateAsync: createConversation,
  } = useFirestoreDocumentMutation(conversationRef);
  const conversationMessagesCollectionRef = collection(
    firestore,
    `conversations/${conversationId}/messages`,
  );
  const {
    mutateAsync: createConversationMessages,
  } = useFirestoreCollectionMutation(conversationMessagesCollectionRef);

  const _handleChatPress = async () => {
    if (!user) {
      return toast.error('Please login to chat with other users');
    }

    if (user?.id === authroId) {
      toast.error("You can't message your self");
      return;
    }
    try {
      if (!conversation) {
        const _conversation = {
          title: `${author?.name?.toLowerCase()} ${currentuserName}`,
          id: conversationId,
          status: 'active',
          createdBy: userId,
          createdAt: Timestamp.now(),
          updatedAt: Timestamp.now(),
          members: conversationMembers,
        };

        await createConversation(_conversation);

        await createConversationMessages({
          _id: 1,
          system: true,
          text: 'converstaion started',
          createdAt: new Date().toDateString(),
        });

        client.setQueriesData(['conversation', conversationId], _conversation);
        client.invalidateQueries(['conversations', userId]);
      }

      navigation.push(`/chat/${conversationId}`);
    } catch (e) {
      toast.error(e?.message);
    }
  };

  const _handleCallPress = () => {
    if (userId === 'null') {
      return toast.error('Please login to call with other users');
    }
    if (author?.phone && author?.includePhone) {
      toast.error('Feature only avaiable on mobile app');
    } else {
      toast.error("User haven't share there phone number");
    }
  };

  const _handleShareBtn = () => {
    navigator.clipboard.writeText(`https://carsnus-1262c.firebaseapp.com/post/${postId}`);
    toast.success('Link Copied');
  };

  if (authroId === userId) {
    return null;
  }

  return (
    <div className="space-y-5 mt-5">
      <Button
        title="Chat"
        size="block"
        variant="secondary"
        loading={isLoading}
        onClick={_handleChatPress}
      />
      <div className="flex items-center space-x-6">
        <Button
          size="block"
          title="Share"
          variant="secondary"
          onClick={_handleShareBtn}
        />
        <Button
          size="block"
          title="Call"
          onClick={_handleCallPress}
        />
      </div>
    </div>
  );
};

const propsAreEqual = (prevProps, nextProps) => prevProps?.author?.id === nextProps?.author?.id
  && prevProps?.postId === nextProps?.postId
  && prevProps?.author?.name === nextProps?.author?.name
  && prevProps?.author?.phone === nextProps?.author?.phone;

/* Export
============================================================================= */
export default React.memo(PostActions, propsAreEqual);
