import React from 'react';
import { NavLink } from 'react-router-dom';
import { ChevronRight } from 'react-feather';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

import { Avatar } from '../../common';

const containerStyle = {
  width: '100%',
  height: '250px',
  borderRadius: 10,
  marginTop: '20px',
};

const center = {
  lat: -3.745,
  lng: -38.523,
};
/* =============================================================================
<PostSellerInfo />
============================================================================= */
const PostSellerInfo = ({ author, location }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAZdzWbvfpPcnvkMg01CjAdrw3zwh-FzjA',
  });
  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback((_map) => {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);

    _map.fitBounds(bounds);

    setMap(_map);
  }, []);

  const onUnmount = React.useCallback(() => {
    setMap(null);
  }, []);

  const userType = author?.type === 'dealer'
    ? 'Verified Auto Dealer'
    : 'Verfied Private Seller';
  const userName = author?.type === 'dealer'
    ? author?.name
    : author?.name?.slice(0, author?.name?.indexOf(' '));

  return (
    <div>
      <NavLink to={`/profile/${author?.id}`} className="flex items-center justify-between mt-5">
        <div className="flex items-center space-x-4">
          <Avatar source={author?.avatar} size="large" name={author?.name} />
          <div>
            <h1 className="text-white text-xl text-semibold">
              {userName}
            </h1>
            <h2 className="text-white text-lg">
              {`${userType} ${author?.business?.address ? `(${author?.business?.address})` : ''}`}
            </h2>
          </div>
        </div>
        <ChevronRight size={35} color="#fff" />
      </NavLink>
      <GoogleMap
        zoom={10}
        onLoad={onLoad}
        center={center}
        onUnmount={onUnmount}
        mapContainerStyle={containerStyle}
      />
    </div>
  );
};

/* Export
============================================================================= */
export default PostSellerInfo;
