import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import { ChevronLeft } from 'react-feather';
import AppLogo from '../assets/images/app-logo.png';
import { classNames } from '../util/functions';

/* =============================================================================
<Header />
============================================================================= */
const Header = ({
  children, stack, title, description, containerClass, childrenContainerClass, titleClass, appLogo,
}) => {
  const history = useHistory();
  return (
    <div className={classNames('bg-gradient-to-t relative  from-black to-primary  pt-2', containerClass)}>
      {appLogo ? (
        <NavLink to="/home">
          <img src={AppLogo} className="h-12 w-22 xsm:w-[8rem] left-6  absolute z-50 object-contain" alt="logo" />
        </NavLink>
      ) : (
        <button type="button" onClick={history.goBack} className="z-50 absolute top-3  left-3 cursor-pointer">
          <ChevronLeft color="white" className="h-6 w-6 lg:h-12 lg:w-12" />
        </button>
      )}
      <div className={classNames(stack && 'mx-19 lg:m-0', childrenContainerClass)}>
        {!!title && (
        <h1 className={classNames('text-white text-4xl font-semibold text-center xsm:text-2xl',
          titleClass)}
        >
          {title}
        </h1>
        )}
        {!!description && <p className="text-white text-xl  text-center">{description}</p>}
        {children}
      </div>
    </div>
  );
};

/* Export
============================================================================= */
export default Header;
