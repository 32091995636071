import React from 'react';

import Modal from './Modal';
import Button from './Button';

/* =============================================================================
<AlertModal />
============================================================================= */
const AlertModal = ({
  visible, children, loading, closeTxt, sumbitTxt, onClose, onSubmit, ...props
}) => (
  <Modal
    heading="Alert"
    visible={visible}
    onClose={onClose}
    {...props}
  >
    {children}
    <div className="flex space-x-2 mt-10">
      <Button title={closeTxt || 'Cancel'} size="block" variant="outline" onClick={onClose} />
      <Button title={sumbitTxt || 'Delete'} size="block" onClick={onSubmit} loading={loading} />
    </div>
  </Modal>
);

/* Export
============================================================================= */
export default AlertModal;
