import React, { useEffect, useState } from 'react';
import kconvert from 'k-convert';
import { MapPin } from 'react-feather';
import { useHistory } from 'react-router-dom';

import { getDistance } from '../util/functions';

/* =============================================================================
<PostCard />
============================================================================= */
const PostCard = ({ item, onPress, children }) => {
  const navigation = useHistory();
  const [distance, setDistance] = useState(0);

  const _handleVehicleDetail = () => {
    if (typeof onPress === 'function') {
      onPress();
    } else {
      navigation.push(`/post/${item?.id}`);
    }
  };

  useEffect(() => {
    const _getDistance = async () => {
      const d = await getDistance(item?.location?.lat, item?.location?.lng);

      setDistance(d);
    };

    _getDistance();
  }, [item?.location]);

  const postTitle = `${item?.year || ''} ${item?.make || item?.titleVehicle} ${
    item?.model || ''
  }`;

  return (
    <button
      type="button"
      onClick={_handleVehicleDetail}
      className="rounded-xl relative overflow-hidden bg-gradient-to-b from-gradiantColor1 to-gradiantColor2"
    >
      {item?.photos?.length > 0 && (
        <img src={item?.photos[0]} alt={item?.id} className="w-full h-44 object-cover md:h-60 rounded-t mb-1" />
      )}

      {item?.isFeatured && (
      <div className="absolute top-0 left-0 m-3 rounded-md py-1 flx items-center justify-center px-3 bg-secondary">
        <p className="text-white text-sm">
          Top
        </p>
      </div>
      )}
      {!!children && children}
      <div className="space-y-2 p-3">
        <p className="text-xl text-left font-bold text-white">
          {postTitle}
        </p>
        <div className="flex justify-between items-center">
          {item?.miles?.toString() ? (
            <p className="text-white text-md text-center">
              {`${kconvert.convertTo(item?.miles?.toString())} m.`}
            </p>
          ) : <div />}
          <p className="text-right text-secondary text-xl font-bold">{`$ ${item?.price}`}</p>
        </div>
        {item?.location?.zipCode && (
          <div className="flex items-center space-x-2">
            <MapPin color="#f1f1f1" size={22} />
            <p className="text-sm text-white ">
              {`${kconvert.convertTo(
                distance,
              )} m to ${item?.location?.zipCode}`}
            </p>
          </div>
        )}
      </div>
    </button>
  );
};

const propsAreEqual = (prevProps, nextProps) => prevProps?.layout === nextProps.layout
  && prevProps?.item?.miles === nextProps?.item?.miles
  && prevProps?.item?.price === nextProps?.item?.price
  && prevProps?.item?.isFeatured === nextProps?.item?.isFeatured
  && prevProps?.item?.titleVehicle === nextProps?.item?.titleVehicle
  && prevProps?.item?.photos?.length === nextProps?.item?.photos?.length
  && prevProps?.queryKey?.toString() === nextProps?.queryKey?.toString()
  && prevProps?.queryRef?.toString() === nextProps?.queryRef?.toString();

/* Export
============================================================================= */
export default React.memo(PostCard, propsAreEqual);
