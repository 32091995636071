import React, { useContext, useState } from 'react';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import {
  ChevronLeft, Eye, EyeOff, X,
} from 'react-feather';
import { Timestamp, doc } from 'firebase/firestore';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFirestoreDocumentMutation } from '@react-query-firebase/firestore';
import { useAuthCreateUserWithEmailAndPassword, useAuthUpdateProfile } from '@react-query-firebase/auth';

import { useHistory } from 'react-router-dom';
import Input from '../Input';
import Button from '../Button';
import AlertModal from '../AlertModal';
import SocialAuthButton from './SocialAuthButton';
import { auth, userCollectionRef } from '../../config/firebase';
import { UserContext } from '../../Provider/UserProvider';
import { classNames } from '../../util/functions';
import useWindowDimensions from '../../hooks/useWindowDimensions';

/* =============================================================================
<Register />
============================================================================= */
const Register = ({ onPageChange, modal, onClose }) => {
  const { getUser } = useContext(UserContext);
  const [successModal, setSuccessModal] = useState(false);
  const { width } = useWindowDimensions();
  const history = useHistory();
  const [togglePassword, setTogglePassword] = useState(false);
  const [toggleCofirmPassword, setToggleConfirmPassword] = useState(false);

  const _toggleSuccessModal = () => setSuccessModal((prev) => !prev);
  const _togglePassword = () => setTogglePassword((prev) => !prev);
  const _toggleConfirmPassword = () => setToggleConfirmPassword((prev) => !prev);

  const _successModalClose = () => {
    if (width > 768) {
      _toggleSuccessModal();
      onClose();
    } else {
      history.push('/');
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(RegisterFormSchema),
  });

  // FireAuth Register Call
  const {
    mutateAsync: createUserWithEmailAndPassword,
    isLoading: createUserLoading,
  } = useAuthCreateUserWithEmailAndPassword(auth, {
    onError(error) {
      toast.error(error.message);
    },
  });
  const { mutateAsync: updatedCurrentUserProfile } = useAuthUpdateProfile();

  // FireStore User Store Call
  const ref = !!auth?.currentUser && doc(userCollectionRef, auth?.currentUser?.uid);
  const {
    isLoading: addUserLoading,
    mutateAsync: addUserToDatabase,
  } = useFirestoreDocumentMutation(ref);

  const onSubmit = async ({
    email, password, name, phone,
  }) => {
    try {
      // create user
      await createUserWithEmailAndPassword({ email, password });

      // update display name
      await updatedCurrentUserProfile({
        user: auth?.currentUser,
        displayName: name,
      });

      // add to database
      await addUserToDatabase({
        type: 'private seller',
        name,
        email,
        phone,
        postPackage: {
          packageId: 'free',
          numberOfPosts: 3,
        },
        favoritePosts: [],
        followers: [],
        id: auth?.currentUser?.uid,
        createdAt: Timestamp.now(),
      });

      await getUser();

      _toggleSuccessModal();
    } catch (e) {
      toast.error(e?.message);
    }
  };

  return (
    <div className={`bg-black  ${!modal && 'h-screen'}`}>
      <div className={classNames('flex  items-center justify-between p-5  bg-gradient-to-t from-black to-primary', modal && 'hidden')}>
        <button type="button" onClick={history.goBack} className="z-50  top-3  left-3 cursor-pointer">
          <ChevronLeft color="white" className="h-6 w-6 lg:h-12 lg:w-12" />
        </button>
        <h1 className="text-center text-2xl font-semibold text-white">Register</h1>
        <div />
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className={`md:max-w-md md:mx-auto md:flex md:flex-col md:items-center  ${!modal && 'h-[calc(100%-100px)]'} md:justify-center px-4 py-3 md:px-0 md:py-0`}>
        <div className="w-full space-y-3">
          <Input label="Full Name" errorText={errors?.name?.message} formProps={register('name')} />
          <Input type="email" label="Email" errorText={errors?.email?.message} formProps={register('email')} />
          <Input label="Phone" errorText={errors?.phone?.message} formProps={register('phone')} />
          <Input
            label="Password"
            onIconClick={_togglePassword}
            formProps={register('password')}
            errorText={errors?.password?.message}
            type={togglePassword ? 'text' : 'password'}
            icon={togglePassword ? <Eye color="#fff" size={22} /> : <EyeOff color="#fff" size={22} />}
          />
          <Input
            label="Confirm Password"
            onIconClick={_toggleConfirmPassword}
            formProps={register('confirmPassword')}
            errorText={errors?.confirmPassword?.message}
            type={toggleCofirmPassword ? 'text' : 'password'}
            icon={toggleCofirmPassword ? <Eye color="#fff" size={22} /> : <EyeOff color="#fff" size={22} />}
          />
        </div>
        <Button type="submit" loading={createUserLoading || addUserLoading} title="Sign in" size="block" customStyleClasses="mt-5" />
        <div className="flex justify-center space-x-2 my-5">
          <SocialAuthButton provider="Fb" />
          <SocialAuthButton provider="Gg" />
          <SocialAuthButton provider="Ap" />
        </div>
        <p className="text-white text-center">
          {'Already have an account? '}
          <button
            type="button"
            className="text-primary hover:opacity-80"
            onClick={() => {
              if (modal && width > 768) {
                onPageChange('Sign in');
              } else {
                history.push('/login');
              }
            }}
          >
            Sign in here
          </button>
        </p>
        <p className="text-white text-center">
          {'Are you a Dealer? '}
          <button
            type="button"
            className="text-primary hover:opacity-80"
            onClick={() => {
              if (width > 768) {
                onPageChange('Dealer Register');
              } else {
                history.push('/dealer/register');
              }
            }}
          >
            Dealer Register
          </button>
        </p>
      </form>
      <AlertModal
        sumbitTxt="Ok"
        closeTxt="Close"
        visible={successModal}
        closeOnBackPress={false}
        onClose={_successModalClose}
        onSubmit={_successModalClose}
      >
        <h1 className="text-white text-center text-xl">
          Post Loaded Successfully ✅
        </h1>
      </AlertModal>
    </div>
  );
};

const RegisterFormSchema = yup
  .object({
    name: yup.string().required('Please enter your full name'),
    email: yup.string().email().required('Please enter email'),
    phone: yup.string().required('Please enter phone number'),
    password: yup.string().required('Please enter password'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required('Please enter password'),
  })
  .required();

/* Export
============================================================================= */
export default Register;
