import React, { useEffect, useState } from 'react';
import kconvert from 'k-convert';
import { NavLink } from 'react-router-dom';

import { MapPin } from 'react-feather';
import { getDistance } from '../../../util/functions';

/* =============================================================================
<PostFeaturedCard />
============================================================================= */
const PostFeaturedCard = ({ post }) => {
  const [distance, setDistance] = useState(0);

  useEffect(() => {
    const _getDistance = async () => {
      const d = await getDistance(post?.location?.lat, post?.location?.lng);

      setDistance(d);
    };

    _getDistance();
  }, [post?.location]);

  const postTitle = `${post?.year || ''} ${post?.make || post?.titleVehicle} ${
    post?.model || ''
  }`;

  return (
    <NavLink to={`/post/${post?.id}`} className="relative rounded-xl overflow-hidden bg-gradient-to-b from-gradiantColor1 to-gradiantColor2  xsm:rounded-none">
      <div className="relative overflow-hidden">
        <img src={post?.photos[0]} className="w-full h-52 mb-2 rounded-xl-t xsm:rounded-none " alt="featured-post" />
        {post?.isFeatured && (
        <div className="absolute top-0 left-0 m-3 rounded-md py-1 flx items-center justify-center px-3 bg-secondary">
          <h2 className="text-white text-sm">
            Top
          </h2>
        </div>
        )}
      </div>
      <div className="w-[95%] bg-[#404040] px-3 py-2 rounded-md space-y-5 xsm:absolute top-40 left-[2.5%]  shadow-2xl ml-auto mr-auto">
        <h1 className="text-white text-xl font-semibold">{postTitle}</h1>
        <div className="flex justify-end items-center ">
          <div className="bg-primary rounded-lg px-4 xsm:px-2 py-1">
            <p className="text-white text-base  font-medium">{`$ ${post?.price}`}</p>
          </div>
        </div>
      </div>
      <div className="space-y-2 p-3 xsm:pt-16 py-8">
        <div className="flex items-center">
          {post?.miles?.toString() && (
          <p className="text-white text-md text-center">
            {`${post?.miles} m.`}
          </p>
          )}

          {post?.fuel && (
          <p className="text-white text-md text-center">
            {`${post?.fuel}`}
          </p>
          )}
        </div>
        {post?.location?.zipCode && (
          <div className="flex items-center space-x-2">
            <MapPin color="#f1f1f1" size={22} />
            <p className="text-sm text-white flex-1">
              {`${kconvert.convertTo(
                distance,
              )} m to ${post?.location?.zipCode}`}
            </p>
          </div>
        )}
      </div>
    </NavLink>
  );
};

/* Export
============================================================================= */
export default PostFeaturedCard;
