import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import {
  limit, orderBy, query, startAfter,
} from 'firebase/firestore';
import { useFirestoreInfiniteQueryData } from '@react-query-firebase/firestore';

import HomeHeader from '../components/HomeHeader';
import { postCollectionRef } from '../../config/firebase';
import { Container, PaginationList, PostCard } from '../../common';

import { setQuery as setQueryAction } from '../../post/redux/actions';
import PostInteractionButtons from '../../post/components/PostInteractionButtons';

/* =============================================================================
<HomeScreen />
============================================================================= */
const HomeScreen = ({ setQuery }) => {
  const history = useHistory();

  const queryKey = ['posts'];
  const queryRef = query(
    postCollectionRef,
    limit(40),
    orderBy('createdAt', 'desc'),
  );
  const {
    data,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useFirestoreInfiniteQueryData(
    queryKey,
    queryRef,
    snapshot => {
      const lastDocument = snapshot[snapshot.length - 1];

      if (!lastDocument) {
        return undefined;
      }

      return query(queryRef, startAfter(lastDocument?.createdAt || ''));
    },
  );

  const flattenData = data?.pages
    ? data?.pages
      ?.flatMap(page => [...page])
      .sort((a, b) => Number(b?.isFeatured) - Number(a?.isFeatured))
    : [];

  const renderItem = useCallback((item, index) => (
    <PostCard
      item={item}
      key={item.id}
      onPress={() => {
        setQuery({ index, queryKey, queryRef });
        history.push('/post');
      }}
    >
      <PostInteractionButtons postId={item.id} />
    </PostCard>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), []);

  return (
    <Container classes="h-[100vh]">
      <Helmet>
        <title>Carsnus</title>
      </Helmet>
      <HomeHeader />
      <PaginationList
        horizontal
        center={false}
        data={flattenData}
        loading={isLoading}
        styles="mx-5 xl:mx-12 mt-0 xsm:mt-0"
        emptyText="No post found"
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        renderItem={renderItem}
      />
    </Container>
  );
};

const mapDispatchToProps = {
  setQuery: setQueryAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(HomeScreen);
