import React, { createContext, useEffect, useState } from 'react';
import {
  collection,
  doc, getDoc, limit, onSnapshot, query, where,
} from 'firebase/firestore';
import {
  useFirestoreQueryData,
  useFirestoreDocumentMutation,
} from '@react-query-firebase/firestore';

import toast from 'react-hot-toast';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { useHistory } from 'react-router-dom';
import { auth, userCollectionRef } from '../config/firebase';
import { generateGuestId } from '../util/functions';

export const UserContext = createContext();

/* =============================================================================
<UserProvider />
============================================================================= */
function UserProvider({ children }) {
  const userId = auth?.currentUser?.uid || 'null';
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [guestId, setGuestId] = useState(null);
  const userDocRef = doc(userCollectionRef, userId);

  const history = useHistory();

  useEffect(() => {
    setGuestId(generateGuestId());
  }, []);

  useEffect(() => onAuthStateChanged(auth, async (data) => {
    if (data) {
      const userDoc = await getDoc(userDocRef);
      setUser(userDoc.data());
    } else {
      setUser(null);
    }
  }), [userId]);

  // useEffect(() => {
  //   setLoading(true);
  //   onSnapshot(userDocRef, async (_userDoc) => {
  //     console.log('daat', _userDoc.data());

  //     setUser(_userDoc.data());
  //     setLoading(false);
  //   });
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userId]);

  const getUser = async () => {
    try {
      setLoading(true);

      const docSnap = await getDoc(userDocRef);
      if (docSnap.exists()) {
        setUser(docSnap.data());
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  function logout() {
    return signOut(auth);
  }

  // UPDATE USER CALL
  const { mutateAsync: updateUser } = useFirestoreDocumentMutation(userDocRef, {
    merge: true,
  });

  return (
    <UserContext.Provider
      value={{
        userId, user, getUser, updateUser, loading, guestId, setGuestId, logout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

/* Export
============================================================================= */
export default UserProvider;
