import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { X } from 'react-feather';
import { classNames } from '../util/functions';

function Modal({
  visible,
  heading,
  size,
  varient,
  onClose,
  children,
  showHeader,
  description,
  customStyles,
  childrenStyles,
  closeOnBackPress,
  transitionChildStyles,
}) {
  const sizeStyles = size === 'sm' ? 'sm:max-w-sm' : size === 'lg' ? 'sm:max-w-lg' : size === 'xl' ? 'sm:max-w-2xl' : 'sm:max-w-lg';
  return (
    <Transition.Root show={visible} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeOnBackPress ? onClose : () => null}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-50 inset-0 overflow-y-auto">
          <div className={classNames('flex items-end items-center justify-center min-h-full p-4 text-center sm:p-0', transitionChildStyles)}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className={`relative ${sizeStyles} ${customStyles} bg-black rounded-lg overflow-hidden text-left shadow-xl transform transition-all my-8 w-full`}>
                {/* header */}
                {showHeader && (
                  <div className={classNames('flex justify-between p-5', varient === 'secondary' ? 'bg-gradient-to-t from-black to-lightBlack' : 'bg-gradient-to-t from-black to-primary')}>
                    <X color="transparent" size={26} />
                    <div>
                      <h1 className="text-center text-2xl font-semibold text-white">{heading}</h1>
                      <p className="text-center text-sm text-white">{description}</p>
                    </div>
                    <button type="button" onClick={onClose}>
                      <X color="#fff" size={26} />
                    </button>
                  </div>
                )}

                {/* main content */}
                <div className={classNames('px-2 md:px-10 py-8', varient === 'secondary' && 'mb-8', childrenStyles)}>
                  {children}
                </div>
                {varient === 'secondary' && <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-lightBlack to-black h-10" /> }
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

Modal.defaultProps = {
  showHeader: true,
  varient: 'primary',
  closeOnBackPress: true,
};

export default Modal;
