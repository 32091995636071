import React from 'react';
import {
  limit,
  query,
  where,
  orderBy,
  startAfter,
} from 'firebase/firestore';
import { useFirestoreInfiniteQueryData } from '@react-query-firebase/firestore';

import { PaginationList } from '../../../common';
import PostFeaturedCard from './PostFeaturedCard';
import { postCollectionRef } from '../../../config/firebase';

/* =============================================================================
<PostFeatured />
============================================================================= */
const PostFeatured = ({ categoryId, currentPostId }) => {
  const title = `${categoryId?.slice(0, 1).toUpperCase()}${categoryId
    ?.slice(1)
    .toLowerCase()}`.replace('_', ' ');

  const queryKey = ['featuredPosts', categoryId];
  const queryRef = query(
    postCollectionRef,
    limit(10),
    orderBy('createdAt'),
    where('isFeatured', '==', true),
    where('category', '==', categoryId),
  );

  const {
    data,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useFirestoreInfiniteQueryData(
    queryKey,
    queryRef,
    snapshot => {
      const lastDocument = snapshot[snapshot.length - 1];

      if (!lastDocument) {
        return undefined;
      }

      return query(queryRef, startAfter(lastDocument?.createdAt || ''));
    },
  );

  const flattenData = data?.pages
    ? data?.pages?.flatMap(page => [...page])
      ?.filter((p) => p?.id !== currentPostId)
    : [];

  if (!flattenData?.length > 0) {
    return null;
  }

  return (
    <div className="my-10">
      <h1 className="text-primary text-2xl font-semibold xsm:mx-3">
        {`Featured ${title}`}
      </h1>
      <PaginationList
        horizontal
        center={false}
        data={flattenData}
        loading={isLoading}
        emptyText="Not Found"
        renderItem={renderItem}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
      />
    </div>
  );
};
const renderItem = (item) => (
  <PostFeaturedCard post={item} key={item.id} />
);

const propsAreEqual = (prevProps, nextProps) => prevProps.currentPostId === nextProps.currentPostId
  && prevProps.categoryId === nextProps.categoryId;

/* Export
============================================================================= */
export default React.memo(PostFeatured, propsAreEqual);
