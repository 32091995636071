import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  limit, orderBy, query, startAfter, where,
} from 'firebase/firestore';
import { useFirestoreInfiniteQueryData } from '@react-query-firebase/firestore';

import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { filterCars } from '../../util/functions';
import { emptyFilterForm } from '../../static/filter';
import { postCollectionRef } from '../../config/firebase';
import {
  Container, LinkHeader, PaginationList, PostCard,
} from '../../common';
import HomeFilterModal from '../components/HomeFilterModal';
import categoriesData from '../../static/categories-data';
import HomeCategoryHeader from '../components/HomeCategoryHeader';

import { setPostData as setPostDataAction } from '../../post/redux/actions';
import HomeCategorySavedButton from '../components/HomeCategorySavedButton';

/* =============================================================================
<HomeCategoryScreen />
============================================================================= */
const HomeCategoryScreen = ({ setPostData }) => {
  const history = useHistory();
  const { categoryId } = useParams();
  const [searchTxt, setSearchTxt] = useState('');
  const [filters, setFilters] = useState(emptyFilterForm);
  const [filtersModal, setFiltersModal] = useState(false);

  const _toggleFiltersModal = () => setFiltersModal(prev => !prev);

  const category = categoriesData.find((c) => c?.id === categoryId);

  const queryKey = ['categoryPosts', categoryId];
  const queryRef = query(
    postCollectionRef,
    limit(40),
    where('category', '==', categoryId),
    orderBy('createdAt', 'desc'),
  );
  const {
    data,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useFirestoreInfiniteQueryData(
    queryKey,
    queryRef,
    snapshot => {
      const lastDocument = snapshot[snapshot.length - 1];

      if (!lastDocument) {
        return undefined;
      }

      return query(queryRef, startAfter(lastDocument?.createdAt || ''));
    },
  );
  const flattenData = data?.pages
    ? data?.pages
      ?.flatMap(page => [...page])
      .sort((a, b) => Number(b?.isFeatured) - Number(a?.isFeatured))
    : [];

  const searchFiltered = useMemo(() => (searchTxt
    ? flattenData?.filter(p => {
      const searchFilter = `${p?.year} ${p?.make} ${p?.model} `.toLowerCase();
      return searchFilter.includes(searchTxt?.trim()?.toLowerCase());
    })
    : filterCars(flattenData, filters)),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [searchTxt, filters, flattenData?.length]);

  const renderItem = (item, index) => (
    <PostCard
      item={item}
      key={item.id}
      onPress={() => {
        setPostData({ index, data: searchFiltered });
        history.push('/post/data');
      }}
    />
  );
  const links = [
    {
      name: 'Home',
      url: '/home',
    },
    {
      name: category?.title,
      url: `/category/${categoryId}`,
    },
  ];

  return (
    <Container>
      <Helmet>
        <title>{`Carsnus | ${category?.title}`}</title>
      </Helmet>
      <HomeCategoryHeader
        searchTxt={searchTxt}
        setSearchTxt={setSearchTxt}
        onFilterPress={_toggleFiltersModal}
      />
      <div className="flex flex-1 justify-between mx-5 xl:mx-12">
        <div className="">
          <div className="hidden md:block">
            <LinkHeader contents={links} />
          </div>
        </div>
        <HomeCategorySavedButton customStylesClasses="pt-2 pb-3" categoryId={categoryId} />
      </div>
      <PaginationList
        horizontal
        center={false}
        data={searchFiltered}
        loading={isLoading}
        styles="mx-5 xsm:my-1 sm-1:my-1 xl:mx-12 xsm:mt-1"
        renderItem={renderItem}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        emptyText="No post found in this category"
      />
      <HomeFilterModal
        filters={filters}
        visible={filtersModal}
        category={categoryId}
        onFilterChange={setFilters}
        onClose={_toggleFiltersModal}
      />
    </Container>
  );
};

const mapDispatchToProps = {
  setPostData: setPostDataAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(HomeCategoryScreen);
