import { useMutation } from 'react-query';
import toast from 'react-hot-toast';

import request from '../../util/request';

/**
 * SEND NOTIFICATION
 */
export const useSendNotification = () => useMutation(
  data => request({
    url: '/notification',
    method: 'POST',
    data,
  }),
  {
    onError: error => {
      toast.error('Something went wrong', error?.message);
    },
  },
);
