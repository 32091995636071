import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import request from '../../util/request';

/**
 * useCreatePayment
 */
export const useCreatePayment = () => useMutation(
  data => request({
    url: '/payments',
    method: 'POST',
    data,
  }),
  {
    onError: error => {
      toast.error('Something went wrong', error?.message);
    },
  },
);
