import React, { useState } from 'react';
import { MapPin } from 'react-feather';
import { fromAddress } from 'react-geocode';

import toast from 'react-hot-toast';
import Modal from './Modal';
import Input from './Input';
import Button from './Button';
import DropDown from './DropDown';
import { classNames } from '../util/functions';

const distanceData = ['25 m.', '50 m.', '100 m.', '500 m.'];

/* =============================================================================
<LocationButton />
============================================================================= */
const LocationButton = ({
  value, showDistance, onChange, locTitleStyle,
}) => {
  const [code, setCode] = useState('');
  const [distance, setDistance] = useState('');
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const _handleApply = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (!code) {
        toast.error('Enter code');
        return;
      }
      const { results } = await fromAddress(code);
      const coordinates = results[0].geometry.location;

      onChange({
        ...coordinates,
        zipCode: code,
        name: results[0]?.formatted_address,
      });

      toggleModal();
    } catch (err) {
      if (err?.message === 'Geocoding request failed: Geocoding failed: undefined. Server returned status code ZERO_RESULTS.') {
        toast.error('Zip code not found');
      } else {
        toast.error(err?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <button type="button" onClick={toggleModal} className="flex space-x-2">
        <p className={classNames('text-white', locTitleStyle)}>{value?.zipCode ? value?.name : 'Location'}</p>
        <MapPin color="#fff" size={28} />
      </button>
      <Modal
        heading="Location"
        visible={modalVisible}
        onClose={toggleModal}
      >
        <div className="flex flex-col justify-between space-y-5">
          <Input
            isFocused
            value={code}
            type="number"
            label="Zip Code"
            placeholder="Enter zip code"
            onChange={(e) => setCode(e.target.value)}
          />
          {showDistance && (
            <DropDown
              label="Distance"
              smallDropDown
              value={distance}
              items={distanceData}
              onChange={setDistance}
              customContainerStyles="z-5000"
            />
          )}
          <Button title="Apply" size="block" loading={loading} onClick={_handleApply} />
        </div>
      </Modal>
    </div>
  );
};

/* Export
============================================================================= */
export default LocationButton;
