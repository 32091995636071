import React from 'react';
import { Provider } from 'react-redux';
import { setKey } from 'react-geocode';
import { Toaster } from 'react-hot-toast';
import { loadStripe } from '@stripe/stripe-js';
import { QueryClientProvider } from 'react-query';
import { Elements } from '@stripe/react-stripe-js';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { PersistGate } from 'redux-persist/integration/react';

import { BrowserRouter as Router } from 'react-router-dom';

import UserProvider from './Provider/UserProvider';
import configureStore from './redux/configureStore';
import AppNavigation from './navigation/AppNavigation';
import { client as queryClient } from './config/react-query';
import {
  GoogleWebClintId,
  GoogleGeoCodingKey,
  StripePublishableKey,
} from './config/secrets';
import ScrollToTop from './components/ScrolltoTop';

setKey(GoogleGeoCodingKey);

const stripePromise = loadStripe(StripePublishableKey);

const { persistor, store } = configureStore();

/* =============================================================================
<App />
============================================================================= */
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <GoogleOAuthProvider clientId={GoogleWebClintId}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <UserProvider>
              <Elements stripe={stripePromise}>
                <Router>
                  <ScrollToTop />
                  <AppNavigation />
                </Router>
              </Elements>
              <Toaster
                position="top-right"
                toastOptions={{
                  duration: 3000,
                }}
              />
            </UserProvider>
          </PersistGate>
        </Provider>
      </GoogleOAuthProvider>
    </QueryClientProvider>
  );
}

/* Export
============================================================================= */
export default App;
