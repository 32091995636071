import React from 'react';
import { connect } from 'react-redux';

import PostInfo from './PostInfo';
import PostActions from './PostActions';

import { getQuery } from '../redux/selectors';

/* =============================================================================
<PostInfoCard />
============================================================================= */
const PostInfoCard = ({ post, author }) => {
  const postId = post?.id;
  const postTitle = `${post?.year || ''} ${post?.make || post?.titleVehicle} ${
    post?.model || ''
  }`;

  return (
    <div className="relative w-full px-4 xsm:rounded-none rounded-md ">
      <div className="w-full bg-grey1 px-3 py-2 rounded-xl space-y-5 xsm:space-y-4 -mt-6 md:-mt-0 z-10 overflow-hidden shadow-2xl mr-auto ml-auto">
        <h1 className="text-white text-3xl font-semibold xsm:text-xl">{postTitle}</h1>
        <div className="flex justify-end items-center">
          <div className="bg-primary rounded-lg px-4 xsm:px-3 py-1">
            <p className="text-white text-2xl xsm:text-md font-medium">{`$ ${post?.price}`}</p>
          </div>
        </div>
      </div>

      <PostInfo post={post} />
      <div className="flex items-center my-5   justify-between">
        {post?.title && (
          <div className="bg-[#555555] px-2 xl:px-5 py-1 rounded-full shadow-lg">
            <p className="text-white text-semibold xsm:text-sm text-center font-bold">{`${post?.title} Title`}</p>
          </div>
        )}
        {post?.condition && (
          <div className="bg-[#555555] px-2 xl:px-5 py-1 rounded-full shadow-lg">
            <p className="text-white text-semibold xsm:text-sm text-center font-bold">{`${post?.condition} Condition`}</p>
          </div>
        )}
      </div>
      <PostActions author={author} postId={postId} />

    </div>
  );
};

const mapStateToProps = (state) => ({
  postQuery: getQuery(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(PostInfoCard);
