import React, { useContext, useState } from 'react';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import { State } from 'country-state-city';
import { ChevronLeft, Eye, EyeOff } from 'react-feather';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Timestamp, doc } from 'firebase/firestore';
import { useFirestoreDocumentMutation } from '@react-query-firebase/firestore';
import { useAuthCreateUserWithEmailAndPassword, useAuthUpdateProfile } from '@react-query-firebase/auth';

import { fromAddress } from 'react-geocode';
import { useHistory } from 'react-router-dom';
import Input from '../Input';
import Button from '../Button';
import AlertModal from '../AlertModal';
import FormDropDown from '../FormDropDown';
import { auth, userCollectionRef } from '../../config/firebase';
import { UserContext } from '../../Provider/UserProvider';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { classNames } from '../../util/functions';

/* =============================================================================
<DealerRegister />
============================================================================= */
const DealerRegister = ({ modal, onPageChange, onClose }) => {
  const { getUser } = useContext(UserContext);
  const [successModal, setSuccessModal] = useState(false);
  const { width } = useWindowDimensions();
  const history = useHistory();
  const [togglePassword, setTogglePassword] = useState(false);
  const [toggleCofirmPassword, setToggleConfirmPassword] = useState(false);

  const _toggleSuccessModal = () => setSuccessModal((prev) => !prev);
  const _togglePassword = () => setTogglePassword((prev) => !prev);
  const _toggleConfirmPassword = () => setToggleConfirmPassword((prev) => !prev);

  const _successModalClose = () => {
    if (width > 768) {
      _toggleSuccessModal();
      onClose();
    } else {
      history.push('/');
    }
  };

  const statesData = State.getStatesOfCountry('US')?.map((s) => s?.name);

  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(DealerFormSchema),
  });

  const { mutateAsync: updatedCurrentUserProfile } = useAuthUpdateProfile();

  // FireAuth Register Call
  const {
    mutateAsync: createUserWithEmailAndPassword,
    isLoading: createUserLoading,
  } = useAuthCreateUserWithEmailAndPassword(auth, {
    onError(error) {
      toast.error(error.message);
    },
  });

  // FireStore User Store Call
  const ref = !!auth?.currentUser && doc(userCollectionRef, auth?.currentUser?.uid);
  const {
    isLoading: addUserLoading,
    mutateAsync: addUserToDatabase,
  } = useFirestoreDocumentMutation(ref);

  const onSubmit = async (values) => {
    try {
      const {
        email,
        name,
        phone,
        city,
        state,
        address,
        postcode,
        password,
        contactName,
      } = values;

      // create user
      await createUserWithEmailAndPassword({ email, password });

      // update display name
      await updatedCurrentUserProfile({
        user: auth?.currentUser,
        displayName: name,
      });

      // add to database
      await addUserToDatabase({
        type: 'dealer',
        name,
        email,
        favoritePosts: [],
        followers: [],
        postPackage: {
          packageId: 'free',
          numberOfPosts: 0,
        },
        business: {
          state,
          city,
          phone,
          address,
          postcode,
          contactName,
        },
        id: auth?.currentUser?.uid,
        createdAt: Timestamp.now(),
      });

      getUser();

      _toggleSuccessModal();
    } catch (e) {
      toast.error(e?.message);
    }
  };

  const onBlur = async e => {
    const { results } = await fromAddress(e.target.value);

    const address = results[0]?.formatted_address;

    setValue('state', results[0].address_components[2]?.long_name);
    setValue('city', address);
  };

  return (
    <div className={`bg-black  overflow-auto  ${!modal && 'h-screen'}`}>
      <div className={classNames('flex  items-center justify-between p-5 bg-gradient-to-t from-black to-primary', modal && 'hidden')}>
        <button type="button" onClick={history.goBack} className="z-50  top-3  left-3 cursor-pointer">
          <ChevronLeft color="white" className="h-6 w-6 lg:h-12 lg:w-12" />
        </button>
        <h1 className="text-center text-2xl font-semibold text-white">Dealer Register</h1>
        <div />
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className={`md:max-w-md md:mx-auto md:flex md:flex-col md:items-center md:justify-center ${!modal && 'h-screen'} px-4 py-3 md:px-0 md:py-0 mt-8 md:mt-0`}>
        <div className="space-y-3">
          <Input label="Bussiness name" errorText={errors?.name?.message} formProps={register('name')} />
          <div className="flex flex-col md:flex-row items-start space-y-3 md:space-y-0 md:space-x-5">
            <Input onBlur={onBlur} label="Zip Code" errorText={errors?.postcode?.message} formProps={register('postcode')} />
            <Input label="City" errorText={errors?.city?.message} formProps={register('city')} />
          </div>
          <div className="flex flex-col md:flex-row items-start space-y-3 md:space-y-0 md:space-x-5">
            <FormDropDown
              name="state"
              label="State"
              control={control}
              data={statesData}
              customContainerStyles="w-full md:w-none"
              errorText={errors?.state?.message}
            />
            <Input label="Phone" errorText={errors?.phone?.message} formProps={register('phone')} />
          </div>
          <Input label="Contact Name" errorText={errors?.contactName?.message} formProps={register('contactName')} />
          <Input label="Address" errorText={errors?.address?.message} formProps={register('address')} />
          <Input type="email" label="Email" errorText={errors?.email?.message} formProps={register('email')} />
          <Input
            label="Password"
            onIconClick={_togglePassword}
            formProps={register('password')}
            errorText={errors?.password?.message}
            type={togglePassword ? 'text' : 'password'}
            icon={togglePassword ? <Eye color="#fff" size={22} /> : <EyeOff color="#fff" size={22} />}
          />
          <Input
            label="Confirm Password"
            onIconClick={_toggleConfirmPassword}
            formProps={register('confirmPassword')}
            errorText={errors?.confirmPassword?.message}
            type={toggleCofirmPassword ? 'text' : 'password'}
            icon={toggleCofirmPassword ? <Eye color="#fff" size={22} /> : <EyeOff color="#fff" size={22} />}
          />
        </div>
        <Button type="submit" loading={createUserLoading || addUserLoading} title="Sign in" size="block" customStyleClasses="mt-5" />
        <p className="text-white text-center mt-5">
          {'Already have an account? '}
          <button
            type="button"
            className="text-primary hover:opacity-80"
            onClick={() => {
              if (modal && width > 768) {
                onPageChange('Sign in');
              } else {
                history.push('/login');
              }
            }}
          >
            Sign in here
          </button>
        </p>
        <p className="text-white text-center">
          {'Are you a private seller? '}
          <button
            type="button"
            className="text-primary hover:opacity-80"
            onClick={() => {
              if (modal && width > 768) {
                onPageChange('Register');
              } else {
                history.push('/register');
              }
            }}
          >
            Register
          </button>
        </p>
      </form>
      <AlertModal
        sumbitTxt="Ok"
        closeTxt="Close"
        visible={successModal}
        closeOnBackPress={false}
        onClose={_successModalClose}
        onSubmit={_successModalClose}
      >
        <h1 className="text-white text-center text-xl">
          Post Loaded Successfully ✅
        </h1>
      </AlertModal>
    </div>
  );
};

const DealerFormSchema = yup
  .object({
    name: yup.string().required('Please enter business name'),
    city: yup.string().required('Please enter city'),
    postcode: yup.string().required('Please enter zip code'),
    state: yup.string().required('Please enter state'),
    phone: yup.string().required('Please enter phone'),
    address: yup.string().required('Please enter address'),
    contactName: yup.string().required('Please enter contact name'),
    email: yup.string().email().required('Please enter email'),
    password: yup.string().required('Please enter password'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required('Please enter password'),
  })
  .required();

/* Export
============================================================================= */
export default DealerRegister;
