import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Timestamp, doc } from 'firebase/firestore';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import {
  getDownloadURL,
  uploadBytesResumable,
  ref as storageRef,
} from 'firebase/storage';
import {
  useFirestoreDocumentData,
  useFirestoreDocumentMutation,
} from '@react-query-firebase/firestore';
import { Info } from 'react-feather';
import { useParams, useHistory } from 'react-router-dom';

import {
  Input,
  Header,
  Button,
  TextArea,
  Container,
  FormDropDown,
  LocationButton,
  ImageMuiltipleUpload,
} from '../../common';
import {
  getBlobFroUri,
  generateYupSchema,
  generateFormDefaultValues,
} from '../../util/functions';
import { postCollectionRef, storage } from '../../config/firebase';
import { postFormData } from '../../static/post';
import { client } from '../../config/react-query';

/* =============================================================================
<EditPostScreen />
============================================================================= */
const EditPostScreen = () => {
  const { id } = useParams();
  const navigation = useHistory();
  const [loading, setLoading] = useState(false);

  const ref = doc(postCollectionRef, id);
  const { data: post, refetch } = useFirestoreDocumentData(['posts', id], ref);
  const { mutateAsync: editPost } = useFirestoreDocumentMutation(ref);

  const fields = postFormData[post?.category || 'cars'];

  const {
    control,
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: generateFormDefaultValues(fields, post),
    resolver: yupResolver(generateYupSchema(fields)),
  });

  // add Default values to form on post fetch
  useEffect(() => {
    if (post) {
      reset(generateFormDefaultValues(fields, post));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post]);

  const onSubmit = async values => {
    try {
      setLoading(true);
      const { photos } = values;

      const changes = {
        ...post,
        ...values,
        updatedAt: Timestamp.now(),
      };

      const photoURLs = await Promise.all(
        photos.map(async photo => {
          if (photo.includes('https://firebasestorage')) {
            return photo;
          }
          const _id = `id${Math.random().toString(16).slice(2)}`;
          const _storageRef = storageRef(storage, `post_images/${_id}`);
          const imageBlob = await getBlobFroUri(photo);
          const snapshot = await uploadBytesResumable(_storageRef, imageBlob);
          const url = await getDownloadURL(snapshot.ref);

          return url;
        }),
      );

      changes.photos = photoURLs;

      await editPost(changes);

      client.invalidateQueries(['posts']);
      client.invalidateQueries(['myPosts']);
      refetch();

      navigation.goBack();
    } catch (e) {
      toast.error(e?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Header title="Edit Post" stack />
      <form onSubmit={handleSubmit(onSubmit)} className="max-w-screen-sm space-y-5 my-5 md:mx-auto mx-5">
        <ImageMuiltipleUpload
          name="photos"
          control={control}
          showAdditionalInfo
          errorText={errors?.photos?.message}
        />
        {fields.map(field => {
          if (field.type === 'text' || field.type === 'number') {
            return (
              <Input
                key={field.name}
                label={field.label}
                errorText={errors[field.name]?.message}
                formProps={register(field.name)}
              />
            );
          }
          if (field.type === 'textArea') {
            return (
              <TextArea
                key={field.name}
                label={field.label}
                errorText={errors[field.name]?.message}
                formProps={register(field.name)}
              />
            );
          }
          if (field.type === 'modal') {
            return (
              <FormDropDown
                key={field.name}
                data={field.data}
                name={field.name}
                control={control}
                label={field.label}
                isMultiSelect={field.isMultiSelect}
                errorText={errors[field.name]?.message}
              />
            );
          }
          return null;
        })}
        <div>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <LocationButton value={value} onChange={onChange} />
            )}
            name="location"
          />
          {!!errors?.location?.message && (
          <div className="flex items-center space-x-1 mt-2">
            <Info color="#dc2626" size={20} />
            <p className="text-md font-medium  py-white text-red-500">
              {errors?.location?.message}
            </p>
          </div>
          )}
        </div>

        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <div className="flex items-center">
              <input
                id="default-checkbox"
                type="checkbox"
                checked={value}
                className="w-6 h-6 text-red-600 bg-gray-200 border-gray-200 rounded focus:ring-red-500"
                onChange={(e) => onChange(e.target.checked)}
              />
              <p className="ml-2 text-md font-medium text-gray-900 dark:text-gray-300">
                Do you want to show your phone number in this post?
              </p>
            </div>
          )}
          name="includePhone"
        />
        <Button
          title="POST"
          type="submit"
          size="block"
          disabled={loading}
          loading={loading}
        />
      </form>
    </Container>
  );
};

/* Export
============================================================================= */
export default EditPostScreen;
